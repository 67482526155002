import React from 'react'

import myImage from "../../images/home.png";

import styles from "../../styles/home.module.css";


export default function Welcome() {
  return (
    <>
    <div style = {{position: "relative"}}>
        <h1 className={styles.title}>Welcome to <br/>Simix</h1>
        <img src={myImage} alt="" className={styles.image}/>
      </div>

      <div className = {styles.welcomeContainer}>
        <h3>WELCOME TO OUR WEBSITE</h3>
        <p>Simix welcomes you and invites you to know about our company and the products and services we offer to national and international clients.</p>
      </div>
    </>
  )
}
