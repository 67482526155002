import React from 'react'

import Welcome from "./Welcome";
import PrincipalPoints from './PrincipalPoints';



export default function Home() {
  return (
    <div id = "Home">
      <Welcome/>
      <PrincipalPoints/>
    </div>
  )
}
