import React from 'react'

import manSilhouette from "../../images/manSilhouette.png"

import IconButton from '@mui/material/IconButton';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';


import styles from "../../styles/business.module.css"

export default function PersonCard(props) {
  return (
    <>
    <img src={manSilhouette} alt="man Silhouette" />
          <h4>{props.name}</h4>
          <hr />
          <div className={styles.description}>
            {props.description}
          </div>
          <div>
                <IconButton className = {styles.social} href = {props.linkedInLink}>
                    <LinkedInIcon/>
                </IconButton>
                <IconButton className = {styles.social} href = {props.gitHubLink}>
                    <GitHubIcon/>
                </IconButton>
          </div>
    </>
  )
}
