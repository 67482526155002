import React from 'react'


import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';

import styles from "../../styles/contact.module.css"

export default function Footer() {
  return (
    <div className = {styles.footer}>
        <hr/>
        <FacebookIcon/>
        <TwitterIcon/>
        <LinkedInIcon/>
        <GitHubIcon/>
      </div>
  )
}
