import React from 'react'

import Footer from './Footer'
import PlaceIcon from '@mui/icons-material/Place';


import styles from "../../styles/contact.module.css"

export default function Contacs() {
  return (
    <div id = "Contacts">
    <div className = {styles.startContact}>
      <h3 style = {{color: "white"}}>WE ARE STARTING TO CONTACT</h3>
      <p>We offer different ways to contact us.</p>
      <div className={styles.location}>
        <PlaceIcon/>
        <h4>ARGENTINA</h4>
        <p>San Francisco, Córdoba, Argentina</p>
        <p>contacto@simix.tech</p>
      </div>
    </div>
    <Footer/>
    </div>
  )
}
