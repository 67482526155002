import React from 'react'

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import VerifiedIcon from '@mui/icons-material/Verified';



import styles from "../../styles/home.module.css";

export default function PrincipalPoints() {
  return (
    <div style={{paddingLeft: "9%"}} className = {styles.containerOfThePoints}>
          <Grid container spacing={4}>
          <Grid item xs={12} lg = {3}>
            <IconButton size="large">
              <ThumbUpIcon style = {{color: "white"}}/>
            </IconButton>
            <h3 className = {styles.pointsText}>CRITICAL FACTORS OF SUCCESS</h3>
            <hr/>
            <p className = {styles.pointsText}>
            Our actions aim to deliver a high value service to customers, based on factors that we share with them throughout the process.
            </p>
            <br/>
            <div className={styles.pointsContainer}>
            <p className = {styles.points}>✔ Trained staff. </p><br/>
            <p className = {styles.points}>✔ Satisfied customers. </p><br/>
            <p className = {styles.points}>✔ Technological innovation. </p><br/>
            <p className = {styles.points}>✔ Personalized service.</p> <br/>
            <p className = {styles.points}>✔Inmediate attention. </p><br/>
            </div>
          </Grid>

          <Grid item xs={12} lg = {3}>
            <IconButton size="large">
              <SettingsSuggestIcon style = {{color: "white"}}/>
            </IconButton>
            <h3 className = {styles.pointsText}>SPECIFIC RESULTS</h3>
            <hr/>
            <p className = {styles.pointsText}>
            We identify critical business processes, where the application of our products and services translates into better business opportunities, efficient use of time and reduction of operating costs.
            </p>
            <br/>
          </Grid>

          <Grid item xs={12} lg = {3}>
            <IconButton size="large">
              <EmojiEventsIcon style = {{color: "white"}}/>
            </IconButton>
            <h3 className = {styles.pointsText}>EXPECTED BENEFITS</h3>
            <hr/>
            <p className = {styles.pointsText}>
            The solutions generated by Simix allow:
            </p>
            <br/>
            <div className={styles.pointsContainer}>
            <p className = {styles.points}>✔ Have accurate information to improve the efficiency of the organization. </p><br/>
            <p className = {styles.points}>✔ Get access to the information at the time and place required.</p><br/>
            <p className = {styles.points}>✔ Optimize activities. </p><br/>
            <p className = {styles.points}>✔ Reduce processing times and administrative costs of information. </p><br/>
            <p className = {styles.points}>✔ Contribute to customer loyalty. </p><br/>
            </div>
          </Grid>

          <Grid item xs={12} lg = {3}>
            <IconButton size="large">
              <VerifiedIcon style = {{color: "white"}}/>
            </IconButton>
            <h3 className = {styles.pointsText}>PATH AND EXPERIENCE</h3>
            <hr/>
            <p className = {styles.pointsText}>
           We have developed a wide variety of software projects, demonstrating strong skills in project design, development, and management. These projects have allowed us to apply different technologies and development methodologies.
            </p>
          </Grid>
          </Grid>
      </div>
  )
}
