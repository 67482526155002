import React from 'react'

import Grid from '@mui/material/Grid';
import PersonCard from './PersonCard';
import WhyChooseUs from './WhyChooseUs';

export default function Business() {
  return (
    <div id = "Business">
      <WhyChooseUs/>
    <Grid container spacing = {4}>
        <Grid item xs = {12} lg = {6}>
            <PersonCard name = "Nicolás Cabrera" linkedInLink = "https://www.linkedin.com/in/nicol%C3%A1scabrera993/" gitHubLink = "https://github.com/Nico993" description = "Persistent, dreamy thinker, and very positive. Planner and a lover of new challenges, dreaming of growing not only personally but also in society. Always thinking big and seeking opportunities to push oneself. Passionate about finding solutions and facing challenges with a positive and proactive attitude."/>          
        </Grid>
        <Grid item xs = {12} lg = {6}>
            <PersonCard name = "Pablo Rodas" linkedInLink = "https://www.linkedin.com/in/prodas/" gitHubLink = "https://github.com/prodass" description = "Passionate about technology and research, with an intense focus on achieving objectives. Curious and passionate about learning and discovering new things, especially in the field of technology and believes in finding streamlined solutions to complex problems."/>
        </Grid>
    </Grid>
    </div>
  )
}
