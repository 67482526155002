import React from 'react'


import styles from "../../styles/home.module.css";

export default function WhyChooseUs() {
  return (
    <div className = {styles.welcomeContainer}>
    <h3>WHY CHOOSE OUR SERVICES</h3>
    <p>In Simix we advise our clients in the choice and use
of software products and services, which provide commercial improvements and new business opportunities.</p>
  </div>
  )
}
