import Home from "./home/Home";
import AppBar from "./AppBar";
import Business from "./business/Business";
import Contacs from "./contacts/Contacs";

import "../styles/root.css";


function App() {

  return (
    <>
      <AppBar/>
      <Home/>
      <Business/>
      <Contacs/>
    </>
  );
}

export default App;
